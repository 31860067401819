import { Schema } from '../../Entities/Schema.entity';
import { createSchema } from '../../Utils/CreateSchema';

export const filterInteractionSchema: Schema = {
  ...createSchema({
    version: '1-1-0',
    description: 'Any toggle of the filters',
    name: 'filter_interaction',
    properties: {
      filter_name: {
        type: 'string',
        description: 'Name of the filter',
      },
      filter_value: {
        type: 'array',
        items: { type: 'string' },
        description: 'Value of the filter',
      },
    },
    required: ['filter_name', 'filter_value'],
  }),
};
