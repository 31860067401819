import { Schema } from '../../Entities/Schema.entity';
import { createSchema } from '../../Utils/CreateSchema';

export const marketingSchema: Schema = {
  ...createSchema({
    version: '1-2-0',
    description: 'Key information of the listing',
    name: 'marketing',
    properties: {
      fbc: {
        type: 'string',
        description: '',
      },
      fbclid: {
        type: 'string',
        description: 'Facebook click',
      },
      gclid: {
        type: 'string',
        description: 'Google Click ID',
      },
      msclkid: {
        type: 'string',
        description: 'Microsoft Click ID',
      },
      ttclid: {
        type: 'string',
        description: 'Tik Tok Click ID',
      },
      ad_id: { type: 'string', description: 'Ad id - usually' },
      OutbrainClickId: { type: 'string', description: 'Outbrain Click id' },
      ScCid: { type: 'string', description: 'Snapchat Click id' },
    },
  }),
};
