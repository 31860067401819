import { createSchema } from '../../../Utils/CreateSchema';
import { Schema } from '../../../Entities/Schema.entity';

export const reservationStartSchema: Schema = {
  ...createSchema({
    version: '1-1-0',
    description: 'Submission of a reserve lead',
    name: 'reserve_start',
    properties: {
      lead_type: {
        type: 'string',
        description: 'The type of lead',
      },
      listing_id: {
        type: 'string',
        description: 'The id of the listing',
      },
    },
    required: ['lead_type', 'listing_id'],
  }),
};
