import { Schema } from '../../../Entities/Schema.entity';
import { createSchema } from '../../../Utils/CreateSchema';

export const leadStartSchema: Schema = {
  ...createSchema({
    version: '1-2-0',
    description: 'The start of a lead form',
    name: 'lead_start',
    properties: {
      lead_type: {
        type: 'string',
        description: 'The type of lead',
      },
      listing_id: {
        type: 'string',
        description: 'The id of the listing',
      },
      cta_location: {
        type: 'string',
        description: 'The location of the CTA (pdp, fincal, etc.)',
      },
    },
    required: ['lead_type', 'listing_id', 'cta_location'],
  }),
};
