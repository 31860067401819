// A couple functions to generate random colors based on a string input.
// This is to get unique colours for each event

export function stringToDistinctHexColour(input: string): string {
  // Generate a pseudo-random but deterministic seed from the input string
  let hash = 0;
  for (let i = 0; i < input.length; i++) {
    hash = input.charCodeAt(i) + ((hash << 5) - hash);
  }

  // Enhance distinctiveness by scrambling the hash using bitwise operations
  hash = (hash ^ 0xdeadbeef) & 0xffffffff; // Add some randomness but deterministic

  // Use HSL for better colour distinction and convert to HEX
  const hue = Math.abs(hash % 360); // Hue ranges from 0-359
  const saturation = 70 + (hash % 30); // Saturation: 70%–100%
  const lightness = 50 + (hash % 10); // Lightness: 50%–60%

  return hslToHex(hue, saturation, lightness);
}

// Helper: Convert HSL to Hexadecimal
export function hslToHex(h: number, s: number, l: number): string {
  s /= 100;
  l /= 100;

  const c = (1 - Math.abs(2 * l - 1)) * s;
  const x = c * (1 - Math.abs(((h / 60) % 2) - 1));
  const m = l - c / 2;

  let r = 0,
    g = 0,
    b = 0;

  if (h < 60) {
    r = c;
    g = x;
    b = 0;
  } else if (h < 120) {
    r = x;
    g = c;
    b = 0;
  } else if (h < 180) {
    r = 0;
    g = c;
    b = x;
  } else if (h < 240) {
    r = 0;
    g = x;
    b = c;
  } else if (h < 300) {
    r = x;
    g = 0;
    b = c;
  } else {
    r = c;
    g = 0;
    b = x;
  }

  r = Math.round((r + m) * 255);
  g = Math.round((g + m) * 200);
  b = Math.round((b + m) * 255);

  return `#${((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1)}`;
}
