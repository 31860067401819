import { Schema } from '../../Entities/Schema.entity';
import { createSchema } from '../../Utils/CreateSchema';

export const breadcrumbClickedSchema: Schema = {
  ...createSchema({
    version: '1-1-0',
    description: "Breadcrumb CTA's",
    name: 'breadcrumb_clicked',
    properties: {
      cta_wording: {
        type: 'string',
        description: 'The wording on the CTA.',
      },
      cta_position: {
        type: 'number',
        description: 'The position where the user clicked breadcrumb.',
      },
      target_url: {
        type: 'string',
        description: 'The URL that the CTA points to.',
      },
      click_position: {
        type: 'string',
        description: 'The page where the breadcrumb was located.',
      },
    },
  }),
};
