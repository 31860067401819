import { Schema } from '../../Entities/Schema.entity';
import { createSchema } from '../../Utils/CreateSchema';

export const searchSortSchema: Schema = {
  ...createSchema({
    version: '1-0-0',
    description: 'Key information of the listing',
    name: 'search_sort',
    properties: {
      sort_order: {
        type: 'string',
        description:
          'Determines the sequence in which the vehicle listings are displayed. Examples might include sorting by newest listings, lowest price, or highest price',
      },
    },
  }),
};
