import { createSchema } from '../../Utils/CreateSchema';
import { Schema } from '../../Entities/Schema.entity';

export const loginSubmitSchema: Schema = {
  ...createSchema({
    version: '1-0-1',
    description: 'Tracks when a user submits their login credentials',
    name: 'login_submit',
    properties: {},
  }),
};
