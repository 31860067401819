import { createSchema } from '../../../../Utils/CreateSchema';
import { Schema } from '../../../../Entities/Schema.entity';

export const consentsSchema: Schema = {
  ...createSchema({
    version: '1-2-0',
    description: 'User consents given features',
    name: 'consents',
    properties: {
      features: {
        type: 'object',
        description: 'List of consents features',
      },
    },
  }),
};
