'use client';

import { memo, useEffect, useMemo, useState } from 'react';

import styles from './EventViewer.module.css';
import { extractSchemaName } from '@tracking/Utils/ExtractSchemaName';
import { isEventViewerActiveAtom } from '@growthBookExperimentation/Components/DevToolsListener';
import { useAtomValue } from 'jotai';
import { stringToDistinctHexColour } from './EventViewerUtils';
import { EventViewerEvent } from './Event.entity';

// Shared state outside React
export const EventViewerTrigger = { value: 0 } as { value: number; forceUpdate: () => void };

export const EventViewerModal = memo(props => {
  const isActive = useAtomValue(isEventViewerActiveAtom);
  const [renderTrigger, setRenderTrigger] = useState(0);
  const [selectedEvent, setSelectedEvent] = useState<EventViewerEvent | null>(null);

  useEffect(() => {
    // Bind external state updates to React state
    EventViewerTrigger.forceUpdate = () => {
      setRenderTrigger(prev => prev + 1);
    };
  }, []);

  const eventHistory = useMemo(() => {
    if (typeof window === 'undefined') return [];
    // @ts-expect-error - window typing
    return [...(window.eventHistory ?? [])].reverse() || [];
  }, [renderTrigger]);

  const toggleSelectedEvent = (event: any) => {
    if (selectedEvent === event) {
      setSelectedEvent(null);
    } else {
      setSelectedEvent(event);
    }
  };

  if (!isActive) return null;

  return (
    <>
      <div className={styles.background} onClick={() => setSelectedEvent(null)} />
      <div className={styles.wrapper}>
        <div className={styles.events}>
          {eventHistory.map((event, index) => (
            <div
              key={event.hash}
              className={styles.event}
              style={{ viewTransitionName: `event-${event['gtm.uniqueEventId']}` }}
              onClick={() => toggleSelectedEvent(event)}
              data-is-active={selectedEvent === event}
            >
              <div
                className={styles.dot}
                style={{
                  backgroundColor: stringToDistinctHexColour(event?.event),
                  borderColor: stringToDistinctHexColour(event?.event + 'O'),
                }}
              />
              {eventHistory.length - index}. {event?.event}
            </div>
          ))}
        </div>

        {selectedEvent && (
          <div className={styles.eventDetail} key={selectedEvent.hash}>
            <div className={styles.eventDetailTitle}>
              <div
                className={styles.dot}
                style={{
                  backgroundColor: stringToDistinctHexColour(selectedEvent?.event),
                  borderColor: stringToDistinctHexColour(selectedEvent?.event + 'O'),
                }}
              />
              {selectedEvent?.event}
            </div>
            <div className={styles.eventData}>{JSON.stringify(selectedEvent?.data, null, 2)}</div>

            {selectedEvent?.context?.map((contextEvent, index) => (
              <div key={index} className={styles.eventDetailContext}>
                <div className={styles.eventDetailTitle}>
                  <div
                    className={styles.dot}
                    style={{
                      backgroundColor: stringToDistinctHexColour(
                        extractSchemaName(contextEvent?.schema)!,
                      ),
                      borderColor: stringToDistinctHexColour(
                        extractSchemaName(contextEvent?.schema) + 'O',
                      ),
                    }}
                  />
                  {contextEvent?.schema}
                </div>

                <div className={styles.eventData}>
                  {JSON.stringify(contextEvent?.data, null, 2)}
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </>
  );
});

EventViewerModal.displayName = 'EventViewerModal';
