import { createSchema } from '../../../Utils/CreateSchema';
import { Schema } from '../../../Entities/Schema.entity';

export const specialFeatureOptinsSchema: Schema = {
  ...createSchema({
    version: '1-2-0',
    description: 'Special feature options',
    name: 'special_features',
    properties: {
      features: {
        type: 'object',
        description: 'List of special features',
      },
    },
  }),
};
