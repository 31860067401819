import { Schema } from '../../../Entities/Schema.entity';
import { createSchema } from '../../../Utils/CreateSchema';

export const createAlertStartSchema: Schema = {
  ...createSchema({
    version: '1-0-0',
    description: 'When a user clicks on the saved search CTA',
    name: 'create_alert_start',
    properties: {},
  }),
};
