import { Schema } from '../../Entities/Schema.entity';
import { createSchema } from '../../Utils/CreateSchema';

export const nlsSchema: Schema = {
  ...createSchema({
    version: '1-0-0',
    description: 'Natural Language Search events',
    name: 'nls',
    properties: {
      query: {
        type: 'string',
        description: 'The natural language search query',
      },
      resultUrl: {
        type: 'string',
        description: 'The resulting URL from the NLS query',
      },
    },
    required: ['query'],
  }),
};
