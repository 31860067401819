import { Schema } from '../../../Entities/Schema.entity';
import { createSchema } from '../../../Utils/CreateSchema';

export const abandonCheckoutModalSchema: Schema = {
  ...createSchema({
    version: '1-0-0',
    description: 'Abandon checkout of any type',
    name: 'abandon_order_popup',
    properties: {
      lead_type: {
        type: 'string',
        description: 'The type of lead',
      },
      listing_id: {
        type: 'string',
        description: 'The id of the listing',
      },
      stage: {
        type: 'string',
        description: 'The stage/step the popup comes',
      },
      btn_action: {
        type: 'string',
        description: 'The action the user has taken within the modal (continue/exit)',
      },
    },
    required: ['lead_type', 'listing_id', 'stage'],
  }),
};
