import { Schema } from '../../../Entities/Schema.entity';
import { createSchema } from '../../../Utils/CreateSchema';

export const createAlertConfirmationSchema: Schema = {
  ...createSchema({
    version: '1-0-0',
    description: 'When a user clicks on the create alert CTA in the modal',
    name: 'create_alert_confirmation',
    properties: {},
  }),
};
