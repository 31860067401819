export const DEBUG_TRANSLATION = 'debugTranslation';
export const NEXT_URL_HREF = 'href';
export const FORCED_FLAG = 'forcedFlag';
export const FORCED_EXPERIMENT = 'forcedExperiment';
export const FORCED_EXPERIMENTS_COOKIE = 'forcedExperiments';
export const FORCED_FLAGS_COOKIE = 'forcedFlags';
export const HC_USER_ID = 'hcUserId';
export const PLP_FILTERS_STATE = 'plpFiltersState';
export const PLP_RESOURCE_PATH = 'plpResourcePath';
export const VISITOR_TYPE = 'visitorType';
