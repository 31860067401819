import { schemas } from '../Schemas/Schemas';

export const getSchemaByRef = (ref: string) => {
  const schema = schemas.find(schema => schema.ref === ref);

  if (!schema) {
    throw new Error(`Schema with ref ${ref} not found`);
  }
  return schema;
};
