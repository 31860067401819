import { Schema } from '../../Entities/Schema.entity';
import { createSchema } from '../../Utils/CreateSchema';

export const listingSchema: Schema = {
  ...createSchema({
    version: '1-4-0',
    description: 'Key information of the listing',
    name: 'listing',
    properties: {
      advertiser_id: {
        type: 'string',
        description: 'Unique identifer of the listing advertiser',
      },
      body_style: {
        type: 'string',
        description: 'The bodystyle of the listing',
      },
      colour: {
        type: 'string',
        description: 'The colour of the listing',
      },
      condition: {
        type: 'string',
        description: 'Condition of the listing: new or used',
      },
      created_at: {
        type: 'string',
        description: 'Date listing was created',
      },
      listing_id: {
        type: 'string',
        description: 'Unique identifer of the listing',
      },
      make: {
        type: 'string',
        description: 'The make of the listing',
      },
      mileage: {
        type: 'number',
        description: 'The mileage of the listing',
      },
      model: {
        type: 'string',
        description: 'The model of the listing',
      },
      monthly_price: {
        type: 'number',
        description: 'The monthly price displayed on the listing',
      },
      price: {
        type: 'number',
        description: 'Price of the listing advertised (including discount)',
      },
      registration: {
        type: 'string',
        description: 'The reigstration of the vehicle',
      },
      variant: {
        type: 'string',
        description: 'The variant of the listing',
      },
      vehicle_name: {
        type: 'string',
        description: 'The entire name of the listing',
      },
      vin: {
        type: 'string',
        description: 'The vin of the listing',
      },
      year: {
        type: 'number',
        description: 'The model year of the listing',
      },
    },
    required: ['advertiser_id', 'listing_id', 'make', 'mileage', 'model', 'price'],
  }),
};
