import { BASE_SCHEMA } from '../Constants/Schema.constants';
import { Schema, SchemaVersion } from '../Entities/Schema.entity';

type Props = {
  name: string;
  description: string;
  properties: Schema['properties'];
  required?: string[];
  version: SchemaVersion;
};

export const createSchema = ({
  name,
  description,
  properties,
  required,
  version: _version,
}: Props): Schema => {
  const version = _version ?? BASE_SCHEMA.self.version;
  const schema = {
    ...BASE_SCHEMA,
    description,
    self: { ...BASE_SCHEMA.self, name, version: version },
    properties,
    required,
    ref: `iglu:com.heycar/${name}/jsonschema/${version}` as const,
  };

  return schema;
};
