import { Schema } from '../../../Entities/Schema.entity';
import { createSchema } from '../../../Utils/CreateSchema';

export const checkoutFinanceSchema: Schema = {
  ...createSchema({
    version: '1-0-0',
    description: 'Checkout Finance Payment Details Filled out',
    name: 'checkout_finance',
    properties: {
      lead_type: {
        type: 'string',
        description: 'The type of lead',
      },
      listing_id: {
        type: 'string',
        description: 'the id of the listing',
      },
    },
    required: ['lead_type', 'listing_id'],
  }),
};
