import { Schema } from '../../../Entities/Schema.entity';
import { createSchema } from '../../../Utils/CreateSchema';

export const leadSubmitSchema: Schema = {
  ...createSchema({
    version: '1-1-0',
    description: 'Submission of a lead',
    name: 'lead_submit',
    properties: {
      lead_type: {
        type: 'string',
        description: 'The type of lead',
      },
      lead_id: {
        type: 'string',
        description: 'The id of the lead from the backend',
      },
      listing_id: {
        type: 'string',
        description: 'The id of the listing',
      },
      captive_id: {
        type: 'string',
        description: 'The id of the captive',
      },
    },
    required: ['lead_type', 'lead_id', 'listing_id'],
  }),
};
