import { Schema } from '../../Entities/Schema.entity';
import { createSchema } from '../../Utils/CreateSchema';

export const vehicleDetailsSchema: Schema = {
  ...createSchema({
    version: '1-1-0',
    description: 'The features that come with the listing',
    name: 'vehicle_details',
    properties: {
      bhp: {
        type: 'number',
        minimum: 0,
        description: 'The power output of an engine measured in terms of horsepower.',
      },
      body_type: {
        type: 'string',
        description: 'Shape or design of the vehicle.',
      },
      co2Gkm: {
        type: 'number',
        minimum: 0,
        description:
          "The amount of carbon dioxide (CO2) emissions produced by the vehicle per kilometer traveled. It's a measure of a car's environmental impact.",
      },
      cubic_capacity: {
        type: 'number',
        minimum: 0,
        description:
          "The volume of the engine's cylinders, typically measured in cubic centimeters (cc). It gives an idea about the size of the engine.",
      },
      doors: {
        type: 'number',
        minimum: 1,
        maximum: 5,
        description: 'The number of doors on the vehicle. The boot in most cases count as a door.',
      },
      engine_size: {
        type: 'number',
        minimum: 0,
        description:
          'Indicates the total volume of all the cylinders in an engine, typically measured in liters.',
      },
      factory_approved: {
        type: 'boolean',
        description:
          'Indicates whether the vehicle has been inspected and certified by its original manufacturer.',
      },
      features: {
        type: 'string',
        description:
          'A list of additional equipment or amenities that the vehicle may have, such as navigation systems, sunroof, leather seats, and more.',
      },
      first_registration: {
        type: 'string',
        description: 'The date when the vehicle was first registered, indicating its age.',
      },
      fuel_consumption: {
        type: 'number',
        minimum: 0,
        description:
          'How much fuel a vehicle uses over a specific distance, typically expressed in liters per 100 kilometers or miles per gallon.',
      },
      fuel_type: {
        type: 'string',
        description:
          'The type of fuel the vehicle uses. Common types include petrol, diesel, electric, hybrid, and LPG.',
      },
      gearbox: {
        type: 'string',
        description:
          'Describes the type of transmission the vehicle has. Common types are manual, automatic, and semi-automatic.',
      },
      insurance_group: {
        type: 'number',
        minimum: 0,
        description:
          'A classification system used by insurers to set premiums based on the vehicle type, performance, repair costs, and other factors. Higher groups usually mean higher insurance costs.',
      },
      is_new_car: {
        type: 'boolean',
        description: 'A boolean indicating whether the vehicle is brand new or used.',
      },
      listing_id: {
        type: 'string',
        description: 'Unique identifier of the listing.',
      },
      previous_owners: {
        type: 'number',
        minimum: 0,
        description:
          'The number of individuals or entities that have owned the vehicle before the current owner or seller.',
      },
      seats: {
        type: 'number',
        minimum: 1,
        description: 'The number of seats in the vehicle, indicating its passenger capacity.',
      },
      ulez: {
        type: 'boolean',
        description:
          'Ultra Low Emission Zone: Indicates whether the vehicle meets the emission standards for entering ultra-low emission zones without incurring a charge. ULEZ standards are common in large cities to reduce pollution.',
      },
      vin: {
        type: 'string',
        description:
          'A unique code used to identify individual motor vehicles. It provides information about the manufacturer, model, engine type, and more.',
      },
    },
    required: [
      // Added some mandatory fields, can adjust accordingly
      'body_type',
      'fuel_type',
      'gearbox',
      'listing_id',
      'vin',
    ],
  }),
};
