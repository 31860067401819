import { Schema } from '../../Entities/Schema.entity';
import { createSchema } from '../../Utils/CreateSchema';

export const callSubmitSchema: Schema = {
  ...createSchema({
    version: '1-0-2',
    description: 'Submission of a lead',
    name: 'call_submit',
    properties: {
      lead_type: {
        type: 'string',
        description: 'The type of lead',
      },
      lead_id: {
        type: 'string',
        description: 'The id of the lead from the backend',
      },
      listing_id: {
        type: 'string',
        description: 'The id of the listing',
      },
      call_number: {
        type: 'string',
        description: 'phone number generated by freespee',
      },
      cta_location: {
        type: 'string',
        description: 'The location of the CTA (leasing page, pdp, etc.)',
      },
    },
    required: ['lead_type', 'lead_id', 'listing_id', 'cta_location'],
  }),
};
