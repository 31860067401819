import { Schema } from '../../../Entities/Schema.entity';
import { createSchema } from '../../../Utils/CreateSchema';

export const checkoutDetailsSchema: Schema = {
  ...createSchema({
    version: '1-0-0',
    description: 'Checkout About You Section completed',
    name: 'checkout_details',
    properties: {
      listing_id: {
        type: 'string',
        description: 'the id of the listing',
      },
    },
    required: ['listing_id'],
  }),
};
