import { atomWithStorage } from 'jotai/utils';

import Cookies from 'js-cookie';

import { HC_USER_ID } from '@cookies/Constants/Cookie.constants';

const cookieStorage = {
  getItem: (key: string | undefined | null) => {
    const cookieValue = key && Cookies.get(key);
    return cookieValue ? cookieValue : null;
  },
  setItem: (key: string, value: string | null | undefined) => {
    if (value === null || value === undefined) {
      Cookies.remove(key);
    } else {
      Cookies.set(key, value);
    }
  },
  removeItem: (key: string) => {
    Cookies.remove(key);
  },
};

export const userIdAtom = atomWithStorage<string | null | undefined>(
  HC_USER_ID,
  undefined,
  cookieStorage,
);
