import { Schema } from '../../Entities/Schema.entity';
import { createSchema } from '../../Utils/CreateSchema';

export const ctaClickedSchema: Schema = {
  ...createSchema({
    version: '1-2-0',
    description:
      'A call to action, whether it is a button or a banner is clicked by the user. Not including specific lead or high value actions',
    name: 'cta_clicked',
    properties: {
      cta_type: {
        type: 'string',
        description: 'banner/button/link',
      },
      cta_wording: {
        type: 'string',
        description: 'The wording on the call to action.',
      },
      target_url: {
        type: 'string',
        description: 'The URL that the call to action points to.',
      },
      cta_location: {
        type: 'string',
        description: 'The location of the CTA (lead form, footer, etc.).',
      },
    },
  }),
};
