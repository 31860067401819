import { Schema } from '../../Entities/Schema.entity';
import { createSchema } from '../../Utils/CreateSchema';

export const userContextSchema: Schema = {
  ...createSchema({
    version: '1-3-0',
    description: 'Key information of the listing',
    name: 'user_context',
    properties: {
      user_id: {
        type: 'string',
        description: 'Unique identifer of the user',
      },
      first_name: {
        type: 'string',
        description: 'The first name of the user submitting the lead',
      },
      last_name: {
        type: 'string',
        description: 'The last name of the user submitting the lead',
      },
      email: {
        type: 'string',
        description: 'The email of the user submitting the lead',
      },
      phone: {
        type: 'string',
        description: 'The phone number of the user submitting the lead',
      },
      postcode: {
        type: 'string',
        description: 'The postcode of the user submitting the lead',
      },
      opted_in: {
        type: 'boolean',
        description: 'If the user wishes to opt for marketing (to send personalised communication)',
      },
      logged_in: {
        type: 'boolean',
        description: 'Whether the user is currently logged in',
      },
    },
  }),
};
