import { isWindowDefined } from '@core/Constants/Window';

type Props = {
  data?: any;
  label: string;
  color?: string;
};

export const clientLog = ({ data, color = '#f7f7f7', label }: Props) => {
  if (process.env.NEXT_PUBLIC_APP_ENV === 'production') return;

  const styles = [
    'padding: 5px',
    `background: ${color || 'white'}`,
    'font-weight: 600',
    'color: black',
  ].join(';');

  if (isWindowDefined) console.info(`%c[${label}]`, styles, data);
};
