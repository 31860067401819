import { Schema } from '../../../Entities/Schema.entity';
import { createSchema } from '../../../Utils/CreateSchema';

export const checkoutSummarySchema: Schema = {
  ...createSchema({
    version: '1-2-0',
    description: 'Checkout payment completed, finaly summary page',
    name: 'checkout_summary',
    properties: {
      lead_type: {
        type: 'string',
        description: 'The type of lead',
      },
      listing_id: {
        type: 'string',
        description: 'the id of the listing',
      },
      stripe_register_id: {
        type: 'string',
        description: 'the register id from stripe',
      },
    },
    required: ['lead_type', 'listing_id', 'stripe_register_id'],
  }),
};
