import { Schema } from '../../../Entities/Schema.entity';
import { createSchema } from '../../../Utils/CreateSchema';

export const hovSubmitSchema: Schema = {
  ...createSchema({
    version: '1-0-0',
    description: "Submitted a 'lead' or email for history of vehicle",
    name: 'hov_submit',
    properties: {},
  }),
};
