import { Schema } from '../../Entities/Schema.entity';
import { createSchema } from '../../Utils/CreateSchema';

export const searchFiltersSchema: Schema = {
  ...createSchema({
    version: '1-1-0',
    description: 'Filters for vehicle listing searches',
    name: 'search_filters',
    properties: {
      sort_order: {
        type: 'string',
        description:
          'Determines the sequence in which the vehicle listings are displayed. Examples might include sorting by newest listings, lowest price, or highest price.',
      },
      postcode: {
        type: 'string',
        description:
          'Allows users to search for vehicles based on a specific postal code, ensuring listings are in or near a desired location.',
      },
      distance: {
        type: 'number',
        description:
          'Filters listings based on proximity to a given postcode or location. For instance, a user might want to see vehicles within 125miles of their home.',
      },
      make: {
        type: 'array',
        items: { type: 'string' },
        description:
          'Refers to the brand or manufacturer of the vehicle. Examples include Ford, Toyota, BMW, etc.',
      },
      model: {
        type: 'array',
        items: { type: 'string' },
        description:
          "Specifies a particular design or version under a vehicle make. For instance, under the make 'Toyota', models might include 'Camry', 'Corolla', etc.",
      },
      variant: {
        type: 'array',
        items: { type: 'string' },
        description:
          'Indicates a specific version or configuration of a vehicle model. Variants often denote differences in engine size, features, or trim levels.',
      },
      body_type: {
        type: 'array',
        items: { type: 'string' },
        description:
          'Allows filtering based on the shape or design of a vehicle, such as coupe, SUV, hatchback, etc.',
      },
      total_price_from: {
        type: 'number',
        description:
          'Sets a minimum price limit, filtering out listings below the specified price.',
      },
      total_price_to: {
        type: 'number',
        description:
          'Sets a maximum price limit, filtering out listings above the specified price.',
      },
      model_year_from: {
        type: 'number',
        description:
          'Filters vehicles based on a starting manufacture year, ensuring listings are of a certain age or newer.',
      },
      model_year_to: {
        type: 'number',
        description:
          'Filters vehicles up to a specified manufacture year, ensuring listings are of a certain age or older.',
      },
      fuel_type: {
        type: 'array',
        items: { type: 'string' },
        description:
          'Allows users to select vehicles based on their fuel source, such as petrol, diesel, electric, hybrid, etc.',
      },
      gearbox: {
        type: 'array',
        items: { type: 'string' },
        description:
          'Filters vehicles by their transmission type, like manual, automatic, or semi-automatic.',
      },
      doors: {
        type: 'number',
        description:
          'Lets users select vehicles based on the number of doors, such as 2-door, 4-door, etc.',
      },
      seats: {
        type: 'number',
        description:
          'Filters listings based on the number of seats in the vehicle, indicating its passenger capacity.',
      },
      buy_online: {
        type: 'boolean',
        description: 'Indicates whether the listing is eligible for eCommerce or not.',
      },
      only_from_heycar: {
        type: 'boolean',
        description: 'Indicates whether the listing is unique and supplied by heycar.',
      },
    },
  }),
};
