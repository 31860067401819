import { Schema } from '../../Entities/Schema.entity';
import { createSchema } from '../../Utils/CreateSchema';

export const searchResultsSchema: Schema = {
  ...createSchema({
    version: '1-0-0',
    description: 'Key information of the listing search results',
    name: 'search_results',
    properties: {
      contents_ids: {
        type: 'array',
        items: { type: 'string' },
        description:
          'An array of all listing ids with each listing in order of being displayed on page',
      },
      results: { type: 'number', description: 'Number of results matching search critera' },
      search_origin: {
        type: 'string',
        description: 'Where did the search originate from, i.e. homepage, search etc',
      },
    },
    required: ['contents_ids'],
  }),
};
