import { Schema } from '../../../Entities/Schema.entity';
import { createSchema } from '../../../Utils/CreateSchema';

export const checkoutStartSchema: Schema = {
  ...createSchema({
    version: '1-2-0',
    description: 'Start of the Checkout journey',
    name: 'checkout_start',
    properties: {
      lead_type: {
        type: 'string',
        description: 'The type of lead',
      },
      listing_id: {
        type: 'string',
        description: 'The id of the listing',
      },
      cta_location: {
        type: 'string',
        description: 'The location of the CTA (PDP, fincalc, etc.)',
      },
    },
    required: ['listing_id'],
  }),
};
