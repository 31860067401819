export const SCHEMA_DEFINITION =
  'http://iglucentral.com/schemas/com.snowplowanalytics.self-desc/schema/jsonschema/1-0-0#';

export const VENDOR = 'com.heycar';
export const FORMAT = 'jsonschema';
export const VERSION = '1-0-0' as const;
export const TYPE = 'object' as const;

export const BASE_SCHEMA = {
  $schema: SCHEMA_DEFINITION,
  self: { vendor: VENDOR, format: FORMAT, version: VERSION },
  type: TYPE,
  properties: {},
  required: [],
  additionalProperties: false,
};
