import { Schema } from '../../../Entities/Schema.entity';
import { createSchema } from '../../../Utils/CreateSchema';

export const hovStartSchema: Schema = {
  ...createSchema({
    version: '1-0-0',
    description: 'Cta to open up the modal for history of vehicle modal',
    name: 'hov_start',
    properties: {},
  }),
};
