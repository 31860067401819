import { createSchema } from '../../../Utils/CreateSchema';
import { Schema } from '../../../Entities/Schema.entity';

export const addFavouritesSchema: Schema = {
  ...createSchema({
    version: '1-0-0',
    description: 'Added favourite vehicle',
    name: 'favourite_added',
    properties: {
      favourite_source: {
        type: 'string',
        description: 'The path where favourite vehicle has been added from',
      },
      listing_id: {
        type: 'string',
        description: 'The id of the listing',
      },
    },
    required: ['favourite_source', 'listing_id'],
  }),
};
