'use client';

import { atom, useAtom } from 'jotai';
import { useEffect } from 'react';

export const isFlagminOpenAtom = atom(false);
export const isEventViewerActiveAtom = atom(false);

export const DevToolsListener = () => {
  const [isOpen, setIsOpen] = useAtom(isFlagminOpenAtom);
  const [isEventViewerActive, setIsEventViewerActive] = useAtom(isEventViewerActiveAtom);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      // Check for Cmd + Shift + F
      if ((event.metaKey || event.ctrlKey) && event.shiftKey && event.key.toLowerCase() === 'f') {
        event.preventDefault();
        setIsOpen(isOpen => !isOpen);
      }

      // Check for Cmd + Shift + E
      if ((event.metaKey || event.ctrlKey) && event.shiftKey && event.key.toLowerCase() === 'e') {
        event.preventDefault();
        event.preventDefault();
        setIsEventViewerActive(isEventViewerActive => !isEventViewerActive);
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    return () => window.removeEventListener('keydown', handleKeyDown);
  }, []);

  return null;
};
