import { Schema } from '@tracking/Entities/Schema.entity';
import { createSchema } from '@tracking/Utils/CreateSchema';

export const scrollToFinanceSchema: Schema = {
  ...createSchema({
    version: '1-0-0',
    description: 'Scroll to finance',
    name: 'finance_clicked',
    properties: {
      vehicle_source: {
        type: 'string',
        description: 'The path where vehicle has been clicked from',
      },
      vehicle_position: {
        type: 'number',
        description: 'The position where vehicle has been clicked from',
      },
      listing_id: {
        type: 'string',
        description: 'The id of the listing',
      },
    },
    required: ['vehicle_source', 'vehicle_position', 'listing_id'],
  }),
};
